import React from "react"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from '../layouts/layout'
import { Helmet } from 'react-helmet'

import Style from './404.module.scss'
import picture from '../images/404.svg'
import { graphql } from "gatsby";

const NotFoundPage = () => {
    const {t} = useTranslation();
    return (
        <div>
            <Layout>
                <Helmet>
                    <title>404</title>
                </Helmet>
                <img className={Style.picture} src={picture} alt="404 Illustration of a guy"/>
                <h1 className={Style.title_h1}>{t("404_title")}</h1>
            </Layout>
        </div>
    )
}

export default NotFoundPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;